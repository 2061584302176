import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ContractInfo, AllPackage, QuotaInfo, UsageHistoryInfo, UserInfo, PackageHoudai } from "../../types/common";
import { userApi } from "../../api/userApi";
import { local } from "../../helper/localStorage";

interface UserState {
  userInfo: UserInfo | null;
  quotaInfo: QuotaInfo | null;
  contractInfo: ContractInfo[] | null;
  usageHistory: UsageHistoryInfo | null;
  allPackage: AllPackage | null;
  packageHoudai: PackageHoudai[] | null;
  loading: boolean;
  quotaLoading: boolean;
  usageHistoryLoading: boolean;
  error: string | null;
}

const initialState: UserState = {
  userInfo: null,
  quotaInfo: null,
  contractInfo: null,
  usageHistory: null,
  allPackage: null,
  packageHoudai: null,
  loading: false,
  quotaLoading: false,
  usageHistoryLoading: false,
  error: null,
};

export const getInfoAccount = createAsyncThunk("customer/showInfo", async (_, { rejectWithValue }) => {
  try {
    const id = local.StorageGetSecure("id");
    const response = await userApi.getInfoAccount(id);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const verifyInfoAccount = createAsyncThunk(
  "customer/verifyInfo",
  async (data: UserInfo, { rejectWithValue }) => {
    try {
      const id = local.StorageGetSecure("id");
      const response = await userApi.verifyInfoAccount(id, data);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateInfoAccount = createAsyncThunk(
  "customer/updateInfo",
  async (data: UserInfo, { rejectWithValue }) => {
    try {
      const id = local.StorageGetSecure("id");
      const response = await userApi.updateInfoAccount(id, data);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getQuotaInfo = createAsyncThunk("customer/getQuotaInfo", async (phone: string, { rejectWithValue }) => {
  try {
    const id = local.StorageGetSecure("id");
    const response = await userApi.getQuotaInfo(id, phone);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const getContractInfo = createAsyncThunk("customer/getContractInfo", async (_, { rejectWithValue }) => {
  try {
    const response = await userApi.getContractInfo();
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const getUsageHistory = createAsyncThunk("customer/getUsageHistory", async (data: any, { rejectWithValue }) => {
  try {
    const id = local.StorageGetSecure("id");
    const response = await userApi.getUsageHistory(id, data.phone, data.month);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const getAllPackage = createAsyncThunk("customer/getAllPackage", async (_, { rejectWithValue }) => {
  try {
    const response = await userApi.getAllPackage();
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const registerServicePackage = createAsyncThunk(
  "customer/registerServicePackage",
  async (data: any, { rejectWithValue }) => {
    try {
      const id = local.StorageGetSecure("id");
      const response = await userApi.registerServicePackage(id, data);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const showPriceHoudai = createAsyncThunk("customer/showPriceHoudai", async (_, { rejectWithValue }) => {
  try {
    const response = await userApi.showPriceHoudai();
    return response;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => ({ ...state, ...action.payload }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInfoAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getInfoAccount.fulfilled, (state, action) => {
        state.loading = false;
        state.userInfo = action.payload;
      })
      .addCase(getInfoAccount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(verifyInfoAccount.pending, (state) => {
        // state.loadingAdditionUserData = true;
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyInfoAccount.fulfilled, (state, action) => {
        // state.loadingAdditionUserData = false;
        state.loading = false;
        state.userInfo = action.payload.data;
      })
      .addCase(verifyInfoAccount.rejected, (state, action) => {
        // state.loadingAdditionUserData = false;
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(updateInfoAccount.pending, (state) => {
        // state.loadingAdditionUserData = true;
        state.loading = true;
        state.error = null;
      })
      .addCase(updateInfoAccount.fulfilled, (state, action) => {
        // state.loadingAdditionUserData = false;
        state.loading = false;
        state.userInfo = action.payload.data;
      })
      .addCase(updateInfoAccount.rejected, (state, action) => {
        // state.loadingAdditionUserData = false;
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(getQuotaInfo.pending, (state) => {
        state.quotaLoading = true;
        state.error = null;
      })
      .addCase(getQuotaInfo.fulfilled, (state, action) => {
        state.quotaLoading = false;
        state.quotaInfo = action.payload;
      })
      .addCase(getQuotaInfo.rejected, (state, action) => {
        state.quotaLoading = false;
        state.error = action.payload as string;
      })
      .addCase(getContractInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getContractInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.contractInfo = action.payload;
      })
      .addCase(getContractInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(getUsageHistory.pending, (state) => {
        state.usageHistoryLoading = true;
        state.error = null;
      })
      .addCase(getUsageHistory.fulfilled, (state, action) => {
        state.usageHistoryLoading = false;
        state.usageHistory = action.payload;
      })
      .addCase(getUsageHistory.rejected, (state, action) => {
        state.usageHistoryLoading = false;
        state.error = action.payload as string;
      })
      .addCase(getAllPackage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllPackage.fulfilled, (state, action) => {
        state.loading = false;
        state.allPackage = action.payload;
      })
      .addCase(getAllPackage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(registerServicePackage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerServicePackage.fulfilled, (state, action) => {
        state.loading = false;
        // state.contractInfo = action.payload;
      })
      .addCase(registerServicePackage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(showPriceHoudai.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(showPriceHoudai.fulfilled, (state, action) => {
        state.loading = false;
        state.packageHoudai = action.payload.data;
      })
      .addCase(showPriceHoudai.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default userSlice.reducer;
export const { setUser } = userSlice.actions;
