import { Button, Checkbox, CheckboxOptionType, Collapse, InputNumber, Radio, Upload, UploadProps } from "antd";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { PaymentMethod, PaymentPrice, UserInfo } from "../../types/common";
import { getInfoAccount } from "../../redux/slices/userSlice";
import { useEffect, useState } from "react";
import overLoader from "../../components/overLoader";
import { getMinPayment, paymentRecord } from "../../redux/slices/paymentSlice";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { RcFile } from "antd/es/upload";
import showMessage from "../../components/openMessage";
import { local } from "../../helper/localStorage";
import { useTranslation } from "react-i18next";

export const Payment = () => {
  const [selectPrice, setSelectPrice] = useState<number>(0);
  const [defaultPrice, setDefaultPrice] = useState<number>(0);
  const [paymentType, setPaymentType] = useState<number>();
  const [confirmPrice, setConfirmPrice] = useState<boolean>(false);
  const [agreeTerms, setAgreeTerms] = useState<boolean>(false);
  const [disableSuggest, setDisableSuggest] = useState<number | null>(null);
  const [listTransactionProof, setListTransactionProof] = useState<{ id: string; url: string }[]>([]);
  //   const [currentOption, setCurrentOption] = useState<number>();
  const [language, setLanguage] = useState<string>("ja");

  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.user.loading);
  const paymentLoading = useAppSelector((state) => state.payment.loading);
  //   const paymentMethod: PaymentMethod[] = useAppSelector((state) => state.payment.paymentMethod) as PaymentMethod[];
  const userInfo: UserInfo = useAppSelector((state) => state.user.userInfo) as UserInfo;
  const paymentPrice: PaymentPrice = useAppSelector((state) => state.payment.paymentPrice) as PaymentPrice;

  const languageLocal = local.StorageGetSecure("language");
  const { t, i18n } = useTranslation(["payment", "common"]);

  useEffect(() => {
    // dispatch(getListPaymentMethod());
    dispatch(getInfoAccount());
    dispatch(getMinPayment());
  }, [dispatch]);
  useEffect(() => {
    setDefaultPrice(paymentPrice?.minPayment);
  }, [paymentPrice?.minPayment]);
  useEffect(() => {
    i18n.changeLanguage(languageLocal || "ja");
    setLanguage(languageLocal || language);
  }, [i18n, language, languageLocal]);

  //   const handleSimChange = (value: number) => {
  //     dispatch(
  //       getMinPayment({
  //         phone: userInfo?.list_sim[value],
  //         month: dayjs().format("YYYYMM"),
  //       }),
  //     );
  //     setDefaultPrice(paymentPrice?.minPayment);
  //     setCurrentOption(value);
  //   };

  //   const optionsSim: CheckboxOptionType[] = userInfo?.list_sim?.map((item, index) => {
  //     return { label: item, value: index };
  //   });

  const isShippingEmpty = userInfo?.shipping?.length === 0;
  const isPaymentEmpty = userInfo?.shipping?.[0]?.payment_method === "" && userInfo?.shipping?.[0]?.payment_code === "";
  const paymentMethod: PaymentMethod[] = [
    { id: 0, name: t("smartpit") },
    { id: 1, name: t("chuyen_khoan") },
    { id: 2, name: t("the_tin_dung") },
  ];
  const optionsPaymentMethod: CheckboxOptionType[] = paymentMethod?.map((item: PaymentMethod) => {
    return {
      value: item.id,
      label: <span className="table-checkout-method-select-text">{item?.name}</span>,
      //   disabled:
      //     item?.id === 0 ? isShippingEmpty || isPaymentEmpty : selectPrice < paymentPrice?.minPayment ? true : false,
      disabled: selectPrice <= 0 ? true : item?.id === 0 ? isShippingEmpty || isPaymentEmpty : false,
    };
  });
  //   console.log(optionsSim);
  //   console.log(paymentPrice);

  const listSuggestMoney = [1000, 3000, 5000, 10000];
  const smartpitFee = 130;
  const stripeFee = Math.round(selectPrice * 0.029 + 40);
  let totalPayment = 0;
  if (confirmPrice && paymentType !== undefined) {
    if (paymentType === 0) {
      totalPayment = selectPrice
        ? selectPrice - paymentPrice?.umobilePoint + smartpitFee
        : paymentPrice?.minPayment - paymentPrice?.umobilePoint + smartpitFee;
    } else if (paymentType === 2) {
      totalPayment = selectPrice ? selectPrice + stripeFee : paymentPrice?.minPayment + stripeFee;
    } else {
      totalPayment = selectPrice
        ? selectPrice - paymentPrice?.umobilePoint
        : paymentPrice?.minPayment - paymentPrice?.umobilePoint;
    }
  }

  const getImageBase64 = (file: RcFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  const propsUpload: UploadProps = {
    listType: "picture",
    maxCount: 2,
    beforeUpload: async (file: RcFile) => {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png";
      if (!isJpgOrPng) {
        showMessage.error(t("vui_long_chon_anh_hop_le"));
        return Upload.LIST_IGNORE;
      }
      const imageBase64 = await getImageBase64(file);
      listTransactionProof?.length < 2 &&
        setListTransactionProof([...listTransactionProof, { id: file.uid, url: imageBase64 as string }]);
      return false;
    },
    onRemove: (file) => {
      const index = listTransactionProof.findIndex((item) => item.id === file.uid);
      if (index !== -1) {
        const newFileList = [...listTransactionProof];
        newFileList.splice(index, 1);
        setListTransactionProof(newFileList);
      }
    },
  };
  const handleSubmitPayment = async () => {
    let data: any = {};
    data = {
      payment_method: paymentType,
      money: totalPayment,
      type: 0,
    };
    if (paymentType === 1) {
      data = {
        ...data,
        image1_base64: listTransactionProof[0].url,
        image2_base64: listTransactionProof[1].url,
      };
    }
    if (totalPayment > 0) {
      try {
        const resData: any = await dispatch(paymentRecord(data));
        // console.log(resData);
        if (resData?.payload?.code === 201) {
          showMessage.success(resData?.payload?.msg);
          setPaymentType(undefined);
          setAgreeTerms(false);
          setListTransactionProof([]);
          setConfirmPrice(false);
          setSelectPrice(0);
          setDefaultPrice(paymentPrice?.minPayment);
          //   setCurrentOption(0);
          dispatch(getMinPayment());
          if (paymentType === 2) {
            window.location.href = resData?.payload?.data?.url_stripe;
          }
        } else {
          showMessage.error(resData?.payload?.msg);
        }
      } catch (error: any) {
        showMessage.error(error?.payload?.msg);
      }
    }
  };

  return (
    <div className="wrapper payment">
      {loading && overLoader()}
      {paymentLoading && overLoader()}
      <div className="main">
        <div className="main-content">
          <div className="payment-list">
            {/* {userInfo?.list_sim?.length === 0 ? (
              <Empty description={t("ban_chua_dang_ki_so_dien_thoai")} />
            ) : ( */}
            <>
              <div className="payment-item">
                <div className="payment-info">
                  <div className="payment-info-item">
                    <span className="payment-info-item-title">{t("so_du_vi")}</span>
                    <span className="payment-info-item-content">{paymentPrice?.customerWallet} 円</span>
                  </div>
                  <div className="payment-info-item">
                    <span className="payment-info-item-title">
                      {/* {dayjs().add(1, "month").month()}月分利用料金 */}
                      {t("thanh_toan_toi_thieu")}
                    </span>
                    <span className="payment-info-item-content">{paymentPrice?.minPayment} 円</span>
                  </div>
                  <div className="payment-info-item">
                    <span className="payment-info-item-title">{t("han_thanh_toan")}</span>
                    <span className="payment-info-item-content">
                      {/* {dayjs().year()}年{dayjs().add(2, "month")}月25日 */}
                      {dayjs().format("YYYY-MM-25")}
                    </span>
                  </div>
                  <div className="payment-info-item">
                    <span className="payment-info-item-title">Umobile Point</span>
                    <span className="payment-info-item-content">{paymentPrice?.umobilePoint}</span>
                  </div>
                  <div className="payment-info-item">
                    <span className="payment-info-item-title">{t("ma_smartpit")}</span>
                    <span className="payment-info-item-content">211 000 333 4455</span>
                  </div>
                </div>
                {/* <div className="payment-button-wrapper">
                    <Button className="payment-button">利用料金.明細確認</Button>
                  </div> */}
              </div>
              <div className="payment-item no-style">
                <div className="payment-table">
                  <div className="payment-table-checkout">
                    <h1 className="table-checkout-title table-checkout-item">{t("thong_tin_thanh_toan")}</h1>
                    <div className="table-checkout-money table-checkout-item">
                      <h2 className="table-checkout-money-title">{t("so_tien_thanh_toan")}</h2>
                      <div className="table-checkout-money-fixed">
                        <span className="table-checkout-money-fixed-content">
                          {/* {selectPrice ? selectPrice : paymentPrice?.minPayment - paymentPrice?.umobilePoint} 円 */}
                          <InputNumber
                            controls={false}
                            addonAfter="円"
                            value={defaultPrice}
                            defaultValue={paymentPrice?.minPayment}
                            onChange={(value) => {
                              value && setDefaultPrice(value);
                              setDisableSuggest(null);
                            }}
                            variant="borderless"
                            className="table-checkout-money-fixed-content-input"
                          />
                        </span>
                        <Button
                          type="primary"
                          className="table-checkout-money-fixed-button"
                          onClick={() => {
                            setConfirmPrice(true);
                            //   setSelectPrice(defaultPrice);
                            if (defaultPrice > paymentPrice?.minPayment) {
                              setSelectPrice(defaultPrice);
                            } else {
                              setSelectPrice(paymentPrice?.minPayment);
                              setDefaultPrice(paymentPrice?.minPayment);
                            }
                          }}
                        >
                          {t("common:xac_nhan")}
                        </Button>
                        <Button
                          className="table-checkout-money-fixed-button"
                          onClick={() => {
                            setDefaultPrice(paymentPrice?.minPayment);
                            setDisableSuggest(null);
                          }}
                          disabled={defaultPrice === paymentPrice?.minPayment}
                        >
                          {t("common:dat_lai")}
                        </Button>
                      </div>
                      <div className="table-checkout-money-suggest">
                        {/* <span className="table-checkout-money-suggest-item">1000円</span>
                      <span className="table-checkout-money-suggest-item">3000円</span>
                      <span className="table-checkout-money-suggest-item">5000円</span>
                      <span className="table-checkout-money-suggest-item">10000円</span> */}
                        <p className="table-checkout-money-suggest-title">{t("chon_nhanh")}</p>
                        <div className="table-checkout-money-suggest-list">
                          {listSuggestMoney.map((item) => (
                            <div
                              key={item}
                              className={`table-checkout-money-suggest-item${
                                item < paymentPrice?.minPayment || disableSuggest === item ? " disabled" : ""
                              }`}
                              onClick={() => {
                                item > paymentPrice?.minPayment && setDefaultPrice(item);
                                setConfirmPrice(false);
                                disableSuggest === item ? setDisableSuggest(null) : setDisableSuggest(item);
                              }}
                            >
                              <span className="table-checkout-money-suggest-item-number">{item}</span>
                              <span className="table-checkout-money-suggest-item-unit">円</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="table-checkout-method table-checkout-item">
                      <h2 className="table-checkout-method-title">{t("phuong_thuc_thanh_toan")}</h2>
                      <Radio.Group
                        options={optionsPaymentMethod}
                        value={paymentType}
                        onChange={(e) => setPaymentType(e.target.value)}
                        className="table-checkout-method-select"
                      />
                    </div>
                  </div>
                  <div
                    className={`payment-table-confirm${
                      !confirmPrice || paymentType === undefined || selectPrice <= 0 ? " disabled" : ""
                    }`}
                  >
                    <h1 className="payment-table-confirm-title">{t("thong_tin_thanh_toan")}</h1>
                    <div className="payment-table-confirm-detail">
                      <div className="payment-table-confirm-detail-item">
                        <span className="payment-table-confirm-detail-item-title">{t("tong_tien")}</span>
                        <span className="payment-table-confirm-detail-item-content">{selectPrice} 円</span>
                      </div>
                      <div className="payment-table-confirm-detail-item">
                        <span className="payment-table-confirm-detail-item-title">{t("giam_gia")}</span>
                        <span className="payment-table-confirm-detail-item-content">
                          -{paymentPrice?.umobilePoint} 円
                        </span>
                      </div>
                      {paymentType === 0 && (
                        <div className="payment-table-confirm-detail-item">
                          <span className="payment-table-confirm-detail-item-title">
                            {t("phi_thanh_toan_smartpit")}
                          </span>
                          <span className="payment-table-confirm-detail-item-content">{smartpitFee} 円</span>
                        </div>
                      )}
                      {paymentType === 2 && (
                        <div className="payment-table-confirm-detail-item">
                          <span className="payment-table-confirm-detail-item-title">{t("phi_thanh_toan_stripe")}</span>
                          <span className="payment-table-confirm-detail-item-content">{stripeFee} 円</span>
                        </div>
                      )}
                    </div>
                    <div className="payment-table-confirm-total">
                      <span className="payment-table-confirm-total-title">{t("tong")}</span>
                      <span className="payment-table-confirm-total-content">{totalPayment} 円</span>
                    </div>
                    <div className="payment-table-confirm-terms">
                      <Checkbox
                        checked={agreeTerms}
                        onChange={(e) => setAgreeTerms(e.target.checked)}
                        className="payment-table-confirm-terms-checkbox"
                        disabled={!confirmPrice || paymentType === undefined}
                      >
                        {t("dong_y_dieu_khoan_thanh_toan")}
                      </Checkbox>
                    </div>
                    {paymentType === 0 ? (
                      <>
                        <div className="payment-table-confirm-info">
                          <span className="payment-table-confirm-info-item">• {t("luu_y_smartpit_1")}</span>
                          <span className="payment-table-confirm-info-item">• {t("luu_y_smartpit_2")}</span>
                          <Collapse
                            items={[
                              {
                                key: 1,
                                label: t("huong_dan_thanh_toan"),
                                children: (
                                  <>
                                    <Link
                                      to="https://www.smartpit.jp/consumer/payment/loppi.html"
                                      target="_blank"
                                      className="payment-table-confirm-info-item-link"
                                    >
                                      • Lawson
                                    </Link>
                                    <Link
                                      to="https://www.smartpit.jp/consumer/payment/familymart.html"
                                      target="_blank"
                                      className="payment-table-confirm-info-item-link"
                                    >
                                      • Family、MiniStop
                                    </Link>
                                  </>
                                ),
                              },
                            ]}
                            ghost
                            collapsible={!confirmPrice ? "disabled" : "header"}
                            className="payment-table-confirm-info-item"
                          />
                        </div>
                      </>
                    ) : paymentType === 1 ? (
                      <div className="payment-table-confirm-info">
                        <span className="payment-table-confirm-info-item">ゆうちょ銀行: 11330-22962241</span>
                        <span className="payment-table-confirm-info-item">カユーティリティーハウス</span>
                        <span className="payment-table-confirm-info-item">店名: 一三八</span>
                        <span className="payment-table-confirm-info-item">店番: 138</span>
                        <span className="payment-table-confirm-info-item note">{t("luu_y_chuyen_khoan")}</span>
                      </div>
                    ) : paymentType === 2 ? (
                      <div className="payment-table-confirm-info">
                        <span className="payment-table-confirm-info-item">{t("phi_thanh_toan_stripe")}: ~ 3%</span>
                        <span className="payment-table-confirm-info-item">{t("mo_ta_stripe")}</span>
                        <span className="payment-table-confirm-info-item">
                          <a
                            href="https://stripe.com/"
                            rel="noreferrer"
                            target="_blank"
                            className="payment-table-confirm-info-item-link"
                          >
                            {t("ve_stripe")}
                          </a>
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="payment-table-confirm-button">
                      {/* {(paymentType === 0 || paymentType === 1) && (
                          <Button
                            type="primary"
                            icon={paymentType === 1 && <i className="fa-light fa-cloud-arrow-up"></i>}
                            iconPosition="start"
                            disabled={!confirmPrice || !agreeTerms}
                            className="payment-table-confirm-btn"
                          >
                            {paymentType === 0 ? "Xác nhận" : paymentType === 1 ? "Tải lên minh chứng" : ""}
                          </Button>
                        )} */}
                      {paymentType === 0 && (
                        <Button
                          type="primary"
                          disabled={!confirmPrice || !agreeTerms}
                          className="payment-table-confirm-btn"
                          onClick={handleSubmitPayment}
                        >
                          {t("common:xac_nhan")}
                        </Button>
                      )}
                      {paymentType === 1 && (
                        <Upload {...propsUpload} multiple className="payment-table-confirm-upload">
                          <Button
                            icon={<i className="fa-light fa-cloud-arrow-up"></i>}
                            iconPosition="start"
                            className="payment-table-confirm-upload-btn"
                          >
                            {t("tai_len_hinh_anh")}
                          </Button>
                        </Upload>
                      )}
                      {paymentType === 1 && (
                        <Button
                          type="primary"
                          disabled={!confirmPrice || !agreeTerms || listTransactionProof.length < 2}
                          className="payment-table-confirm-btn"
                          onClick={handleSubmitPayment}
                        >
                          {t("common:xac_nhan")}
                        </Button>
                      )}
                      {paymentType === 2 && (
                        <Button
                          type="primary"
                          disabled={!confirmPrice || !agreeTerms}
                          className="payment-table-confirm-btn"
                          onClick={handleSubmitPayment}
                        >
                          {t("common:xac_nhan")}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
            {/* )} */}
          </div>
        </div>
      </div>
    </div>
  );
};
