import axiosClient from "../axiosClient";

export const paymentApi = {
  async getListPaymentMethod() {
    const url = "/customer/payment/list-method";
    return axiosClient.get(url);
  },

  async getMinPayment() {
    const url = `/customer/payment/check`;
    return axiosClient.get(url);
  },

  async paymentRecord(id: number, data: any) {
    const url = `/customer/payment/${id}/new-record`;
    return axiosClient.post(url, data);
  },

  async getPaymentByMonth(year: string, month: string) {
    const url = `/customer/payment/month/${year}${month}`;
    return axiosClient.get(url);
  },
};
