import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PaymentMethod, PaymentMonth, PaymentPrice } from "../../types/common";
import { paymentApi } from "../../api/paymentApi";
import { local } from "../../helper/localStorage";

interface PaymentState {
  paymentMethod: PaymentMethod[] | null;
  paymentPrice: PaymentPrice | null;
  paymentMonth: PaymentMonth | null;
  loading: boolean;
  error: string | null;
}
const initialState: PaymentState = {
  paymentMethod: null,
  paymentPrice: null,
  paymentMonth: null,
  loading: false,
  error: null,
};

export const getListPaymentMethod = createAsyncThunk(
  "customer/getListPaymentMethod",
  async (_, { rejectWithValue }) => {
    try {
      const response = await paymentApi.getListPaymentMethod();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getMinPayment = createAsyncThunk("customer/getMinPayment", async (_, { rejectWithValue }) => {
  try {
    const response = await paymentApi.getMinPayment();
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const paymentRecord = createAsyncThunk("customer/paymentRecord", async (data: any, { rejectWithValue }) => {
  try {
    const id = local.StorageGetSecure("id");
    const response = await paymentApi.paymentRecord(id, data);
    return response;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const getPaymentByMonth = createAsyncThunk(
  "customer/getPaymentByMonth",
  async (data: { year: string; month: string }, { rejectWithValue }) => {
    try {
      const response = await paymentApi.getPaymentByMonth(data.year, data.month);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListPaymentMethod.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getListPaymentMethod.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentMethod = action.payload;
      })
      .addCase(getListPaymentMethod.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(getMinPayment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMinPayment.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentPrice = action.payload;
      })
      .addCase(getMinPayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(paymentRecord.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(paymentRecord.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(paymentRecord.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(getPaymentByMonth.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPaymentByMonth.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentMonth = action.payload;
      })
      .addCase(getPaymentByMonth.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default paymentSlice.reducer;
