import { useEffect, useState } from "react";
// import { Header } from "../../components/Header";
import { Collapse, Empty, Select } from "antd";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { ContractInfo } from "../../types/common";
import { getContractInfo, getInfoAccount } from "../../redux/slices/userSlice";
import overLoader from "../../components/overLoader";
import { local } from "../../helper/localStorage";
import { useTranslation } from "react-i18next";
// import { useTranslation } from "react-i18next";

export const ServiceInfo = () => {
  //   const [collapseSidebar, setCollapseSidebar] = useState<boolean>(false);
  //   const [openModalReissueSim, setOpenModalReissueSim] = useState<boolean>(false);
  //   const [openModalStorageSim, setOpenModalStorageSim] = useState<boolean>(false);
  const [currentSim, setCurrentSim] = useState<number>(0);

  // redux
  const dispatch = useAppDispatch();
  const loading: boolean = useAppSelector((state) => state.user.loading);
  const quotaLoading: boolean = useAppSelector((state) => state.user.quotaLoading);
  //   const userInfo: UserInfo = useAppSelector((state) => state.user.userInfo) as UserInfo;
  //   const quotaInfo: QuotaInfo = useAppSelector((state) => state.user.quotaInfo) as QuotaInfo;
  const contractInfo: ContractInfo[] | null = useAppSelector((state) => state.user.contractInfo);

  const token = local.StorageGetSecure("access_token");
  const languageLocal = local.StorageGetSecure("language");
  const { t, i18n } = useTranslation(["service_info"]);

  useEffect(() => {
    if (token) {
      dispatch(getInfoAccount());
      dispatch(getContractInfo());
      //   dispatch(getAllPackage());
    }
    // if (userInfo?.phone) {
    //   dispatch(getQuotaInfo(userInfo?.phone));
    // }
  }, [dispatch, token]);
  useEffect(() => {
    i18n.changeLanguage(languageLocal || "ja");
  }, [i18n, languageLocal]);

  const faqList = [
    {
      key: 1,
      label: (
        <div className="service-item-faq-question">
          <p className="service-item-faq-question-text">
            {t("faq_q1")}
            <span className="service-item-faq-question-icon">
              <i className="fa-light fa-arrow-right"></i>
            </span>
          </p>
        </div>
      ),
      children: t("faq_a1"),
    },
    {
      key: 2,
      label: (
        <div className="service-item-faq-question">
          <p className="service-item-faq-question-text">
            {t("faq_q2")}
            <span className="service-item-faq-question-icon">
              <i className="fa-light fa-arrow-right"></i>
            </span>
          </p>
        </div>
      ),
      children: (
        <>
          <ul>
            <li>{t("faq_a2_1")}</li>
            <li>
              {t("faq_a2_2")}
              <br />
              {t("faq_a2_3")}
              <br />
              {t("faq_a2_4")}
              <br />
              {t("faq_a2_5")}
            </li>
            <li>
              {t("faq_a2_6")}
              <a href="https://m.me/utilitymobile.jp" target="_blank" rel="noreferrer" className="faq-link">
                https://m.me/utilitymobile.jp
              </a>
              <br />
              {t("faq_a2_7")}
            </li>
          </ul>
          <p>{t("faq_a2_8")}</p>
        </>
      ),
    },
    {
      key: 3,
      label: (
        <div className="service-item-faq-question">
          <p className="service-item-faq-question-text">
            {t("faq_q3")}
            <span className="service-item-faq-question-icon">
              <i className="fa-light fa-arrow-right"></i>
            </span>
          </p>
        </div>
      ),
      children: (
        <>
          <ul>
            <li>{t("faq_a3_1")}</li>
            <li>{t("faq_a3_2")}</li>
          </ul>
          <p>{t("faq_a3_note")}</p>
        </>
      ),
    },
    {
      key: 4,
      label: (
        <div className="service-item-faq-question">
          <p className="service-item-faq-question-text">
            {t("faq_q4")}
            <span className="service-item-faq-question-icon">
              <i className="fa-light fa-arrow-right"></i>
            </span>
          </p>
        </div>
      ),
      children: (
        <ul>
          <li>{t("faq_a4_1")}</li>
          <li>
            {t("faq_a4_2")}
            <a
              href="https://umobile.co.jp/blog/huong-dan-cai-dat-cau-hinh-sim-nghe-goi-umobile/"
              target="_blank"
              rel="noreferrer"
              className="faq-link"
            >
              https://umobile.co.jp/blog/huong-dan-cai-dat-cau-hinh-sim-nghe-goi-umobile/
            </a>
          </li>
        </ul>
      ),
    },
    {
      key: 5,
      label: (
        <div className="service-item-faq-question">
          <p className="service-item-faq-question-text">
            {t("faq_q5")}
            <span className="service-item-faq-question-icon">
              <i className="fa-light fa-arrow-right"></i>
            </span>
          </p>
        </div>
      ),
      children: <p>{t("faq_a5")}</p>,
    },
    {
      key: 6,
      label: (
        <div className="service-item-faq-question">
          <p className="service-item-faq-question-text">
            {t("faq_q6")}
            <span className="service-item-faq-question-icon">
              <i className="fa-light fa-arrow-right"></i>
            </span>
          </p>
        </div>
      ),
      children: (
        <>
          <p>
            {t("faq_a6_1")}
            <br />
            {t("faq_a6_2")}
            <br />
            {t("faq_a6_3")}
          </p>
          <p>
            {t("faq_a6_4")}
            <br />
            {t("faq_a6_5")}
          </p>
          <p>
            {t("faq_a6_6")}
            <a
              href="https://umobile.co.jp/qui-dinh-thanh-toan-dong-cuoc/"
              target="_blank"
              rel="noreferrer"
              className="faq-link"
            >
              https://umobile.co.jp/qui-dinh-thanh-toan-dong-cuoc/
            </a>
          </p>
        </>
      ),
    },
    {
      key: 7,
      label: (
        <div className="service-item-faq-question">
          <p className="service-item-faq-question-text">
            {t("faq_q7")}
            <span className="service-item-faq-question-icon">
              <i className="fa-light fa-arrow-right"></i>
            </span>
          </p>
        </div>
      ),
      children: (
        <>
          <p>
            {t("faq_a7_1")}
            <br />
            {t("faq_a7_2")}
          </p>
          <>
            <table className="faq-table faq-table-service">
              <tbody>
                <tr className="faq-table-row">
                  <td rowSpan={3} className="faq-table-cell faq-table-service-name">
                    LINE/Skype
                  </td>
                  <td className="faq-table-cell faq-table-service-detail">{t("tin_nhan")}</td>
                  <td className="faq-table-cell">〇</td>
                </tr>
                <tr className="faq-table-row">
                  <td className="faq-table-cell faq-table-service-detail">{t("goi_thoai")}</td>
                  <td className="faq-table-cell">〇</td>
                </tr>
                <tr className="faq-table-row">
                  <td className="faq-table-cell faq-table-service-detail">{t("goi_video")}</td>
                  <td className="faq-table-cell">△</td>
                </tr>
                <tr className="faq-table-row">
                  <td colSpan={2} className="faq-table-cell faq-table-service-name">
                    Twitter
                  </td>
                  <td className="faq-table-cell">△</td>
                </tr>
                <tr className="faq-table-row">
                  <td colSpan={2} className="faq-table-cell faq-table-service-name">
                    Instagram
                  </td>
                  <td className="faq-table-cell">×</td>
                </tr>
                <tr className="faq-table-row">
                  <td colSpan={2} className="faq-table-cell faq-table-service-name">
                    Facebook
                  </td>
                  <td className="faq-table-cell">×</td>
                </tr>
                <tr className="faq-table-row">
                  <td rowSpan={2} className="faq-table-cell faq-table-service-name">
                    WEB
                  </td>
                  <td className="faq-table-cell faq-table-service-detail text-bold">{t("ban_do_tin_tuc")}</td>
                  <td className="faq-table-cell">△</td>
                </tr>
                <tr className="faq-table-row">
                  <td className="faq-table-cell faq-table-service-detail text-bold">{t("trang_mua_sam")}</td>
                  <td className="faq-table-cell">△</td>
                </tr>
                <tr className="faq-table-row">
                  <td rowSpan={3} className="faq-table-cell faq-table-service-name">
                    Youtube
                  </td>
                  <td className="faq-table-cell faq-table-service-detail text-bold">{t("chat_luong_thap")}</td>
                  <td className="faq-table-cell">△</td>
                </tr>
                <tr className="faq-table-row">
                  <td className="faq-table-cell faq-table-service-detail">{t("chat_luong_trung_binh")}</td>
                  <td className="faq-table-cell">×</td>
                </tr>
                <tr className="faq-table-row">
                  <td className="faq-table-cell faq-table-service-detail">{t("chat_luong_cao")}</td>
                  <td className="faq-table-cell">×</td>
                </tr>
                <tr className="faq-table-row">
                  <td colSpan={2} className="faq-table-cell faq-table-service-name">
                    Tethering
                  </td>
                  <td className="faq-table-cell">△</td>
                </tr>
                <tr className="faq-table-row">
                  <td colSpan={2} className="faq-table-cell faq-table-service-name">
                    Game online
                  </td>
                  <td className="faq-table-cell">△</td>
                </tr>
                <tr className="faq-table-row">
                  <td rowSpan={2} className="faq-table-cell faq-table-service-name">
                    {t("du_lieu")}
                  </td>
                  <td className="faq-table-cell faq-table-service-detail">{t("tai_xuong")}</td>
                  <td rowSpan={2} className="faq-table-cell">
                    ×
                  </td>
                </tr>
                <tr className="faq-table-row">
                  <td className="faq-table-cell faq-table-service-detail">{t("tai_len")}</td>
                </tr>
              </tbody>
            </table>
            <p>
              〇 {t("khong_van_de")}
              <br />△ {t("cham_khong_on_dinh")}
              <br />× {t("khong_the_su_dung")}
            </p>
          </>
        </>
      ),
    },
  ];
  const optionsListSim = contractInfo?.map((item: ContractInfo, index: number) => {
    return {
      label: item.phone,
      value: index,
    };
  });

  return (
    <div className="wrapper service">
      {loading && overLoader()}
      {quotaLoading && overLoader()}
      <div className="main">
        <div className="main-content">
          <div className="service-item">
            {contractInfo && contractInfo?.length > 0 ? (
              <div className="service-item-info">
                {/* <h1 className="service-item-info-phone">{userInfo?.phone || "––"}</h1> */}
                <Select
                  options={optionsListSim}
                  defaultValue={0}
                  value={currentSim}
                  defaultActiveFirstOption
                  onChange={(value: number) => {
                    setCurrentSim(value);
                  }}
                  className="service-item-info-phone"
                />
                <div className="service-item-info-package">
                  <div className="service-item-info-package-item">
                    <span className="service-item-info-package-item-label">{t("ten_goi")}: </span>
                    <span className="service-item-info-package-item-value">
                      {
                        contractInfo?.find((item: ContractInfo) => item.phone === optionsListSim?.[currentSim]?.label)
                          ?.package_name
                      }
                    </span>
                  </div>
                  <div className="service-item-info-package-item">
                    <span className="service-item-info-package-item-label">{t("gia_cuoc")}: </span>
                    <span className="service-item-info-package-item-value">
                      {
                        contractInfo?.find((item: ContractInfo) => item.phone === optionsListSim?.[currentSim]?.label)
                          ?.package_price
                      }
                      円/月
                    </span>
                  </div>
                  <div className="service-item-info-package-item">
                    {/* Ngày đăng ký: {contractInfo?.filter((item: ContractInfo) => item.status === 1)[0]?.start_date} */}
                    <span className="service-item-info-package-item-label">{t("thoi_gian_hieu_luc")}: </span>
                    <span className="service-item-info-package-item-value">
                      {/* {`${contractInfo?.filter((item: ContractInfo) => item.status === 1)[currentSim]?.start_date} - ${
                      contractInfo?.filter((item: ContractInfo) => item.status === 1)[currentSim]?.end_date
                    }`} */}
                      {`${
                        contractInfo?.find((item: ContractInfo) => item.phone === optionsListSim?.[currentSim]?.label)
                          ?.start_date
                      } - ${
                        contractInfo?.find((item: ContractInfo) => item.phone === optionsListSim?.[currentSim]?.label)
                          ?.end_date
                      }`}
                    </span>
                  </div>
                  {/* <div className="service-item-info-package-item">
                    <Button
                      type="primary"
                      onClick={async () => {
                        const contractID = contractInfo?.find(
                          (item: ContractInfo) => item.phone === optionsListSim?.[currentSim]?.label,
                        )?.id;
                        if (contractID) {
                          const response = await userApi.downloadContract(contractID);
                          console.log("response", response);
                        }
                      }}
                    >
                      Download Contract
                    </Button>
                  </div> */}
                </div>
              </div>
            ) : (
              <Empty description={t("ban_chua_dang_ki_so_dien_thoai")} />
            )}
          </div>
          <div className="service-item">
            <div className="service-item-faq">
              <h1 className="service-item-faq-title">FAQ</h1>
              <div className="service-item-faq-list">
                <Collapse items={faqList} accordion ghost className="service-item-faq-accordion" />
              </div>
            </div>
            {/* <div className="service-item-support">
              <Link to="/support" className="service-item-support-link">
                サポート画面から質問を探す
              </Link>
            </div> */}
          </div>
        </div>
      </div>
      {/* <div className={`overlay${openSidebar ? " active" : ""}`} onClick={() => setOpenSidebar(!openSidebar)}></div> */}
    </div>
  );
};
