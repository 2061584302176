import { Button, Form, Input, Modal } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { authApi } from "../../../api/authApi";
import overLoader from "../../../components/overLoader";
import { DataLogin } from "../../../types/auth";
import { local } from "../../../helper/localStorage";
import { ForgotPassword } from "../forgotPassword";
import { useTranslation } from "react-i18next";
import images from "../../../assets/images";
import showMessage from "../../../components/openMessage";

export const Login = () => {
  // const [openRegister, setOpenRegister] = useState<boolean>(false);
  const [openForgotPassword, setOpenForgotPassword] = useState<boolean>(false);
  const [statusLoader, setStatusLoader] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>("ja");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(["auth", "common"]);
  const languageLocal = local.StorageGetSecure("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "ja");
    setLanguage(languageLocal || language);
  }, [languageLocal, language, i18n]);

  const handleChangeLanguage = (lang: string) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
    local.StorageSetSecure("language", lang);
  };

  const handleLogin: any = async (data: DataLogin) => {
    setStatusLoader(true);
    localStorage.clear();
    try {
      const resData: any = await authApi.login(data);
      if (resData.code === 201) {
        local.StorageSetSecure("access_token", JSON.stringify(resData.data.token));
        local.StorageSetSecure("id", resData.data.dataShow.id);
        setStatusLoader(false);
        showMessage.success(resData?.msg);
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      } else {
        setStatusLoader(false);
        showMessage.error(resData?.msg);
      }
    } catch (err: any) {
      setStatusLoader(false);
      showMessage.error(err?.response?.data?.msg);
    }
  };
  return (
    <div className="wrapper login">
      {statusLoader && overLoader()}
      <Modal
        title={t("quen_mat_khau")}
        centered
        className="forgot-password-modal"
        open={openForgotPassword}
        onCancel={() => setOpenForgotPassword(false)}
        footer={null}
      >
        <ForgotPassword handleShowModal={() => setOpenForgotPassword(!openForgotPassword)} />
      </Modal>
      {/* <Modal
        title="アカウント登録"
        centered
        className="register-modal"
        open={openRegister}
        onCancel={() => setOpenRegister(false)}
        footer={null}
      >
        <Register handleShowModal={() => setOpenRegister(!openRegister)} />
        <div className="return-login">
          <span className="return-login-text">すでにアカウントをお持ちですか? </span>
          <span className="return-login-button" onClick={() => setOpenRegister(false)}>
            ログイン?
          </span>
        </div>
      </Modal>
      <Modal
        title="パスワードを忘れた"
        centered
        className="forgot-password-modal"
        open={openForgotPassword}
        onCancel={() => setOpenForgotPassword(false)}
        footer={null}
      >
        <ForgotPassword handleShowModal={() => setOpenForgotPassword(!openForgotPassword)} />
      </Modal>
      <div className="login container">
        <div className="login-logo">
          <img src={image_logo} alt="" />
        </div>
        <Form
          className="login-form"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={handleLogin}
          autoComplete="off"
        >
          <Form.Item
            label="メ ー ル"
            name="email"
            className="form-item"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input className="form-item-input" />
          </Form.Item>
          <Form.Item
            label="パスワード"
            name="password"
            className="form-item"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password className="form-item-input" />
          </Form.Item>
          <Button type="primary" htmlType="submit" className="submit-button">
            ログイン
          </Button>
        </Form>
        <button className="forgot-password" onClick={() => setOpenForgotPassword(true)}>
          バスワ ー ドを忘れた
        </button>
        <div className="register">
          <span className="register-text">また Umobile アカウントをお持ちでない方は、</span>
          <span className="register-button" onClick={() => setOpenRegister(true)}>
            今すぐしてくたさい-、
          </span>
        </div>
      </div> */}
      <button className="login-language" onClick={() => handleChangeLanguage(language === "ja" ? "vi" : "ja")}>
        <div className="login-language-icon">
          <img src={language === "ja" ? images.image_vn_flag : images.image_jp_flag || images.image_jp_flag} alt="" />
        </div>
      </button>
      <div className="login-welcome">
        <div className="login-welcome-header">
          {/* <div className="login-welcome-header-logo"> */}
          <img src={images.image_logo} alt="" />
          {/* </div>
          <span className="login-welcome-header-text">Umobile</span> */}
        </div>
        <h1 className="login-welcome-title">{t("chao_mung_dang_nhap")}</h1>
        <p className="login-welcome-description">{t("gioi_thieu_umobile")}</p>
      </div>
      <div className="login-form container">
        <h1 className="login-form-title">{t("dang_nhap")}</h1>
        <div className="login-form-create">
          <span>{t("chua_co_tai_khoan")}</span>
          <Link to="/register" className="login-form-create-link">
            {t("tao_tai_khoan")}
          </Link>
        </div>
        <Form
          className="login-form-main"
          layout="vertical"
          // labelCol={{ span: 8 }}
          // wrapperCol={{ span: 16 }}
          colon={false}
          initialValues={{ remember: true }}
          onFinish={handleLogin}
          autoComplete="off"
        >
          <Form.Item
            label={t("email")}
            name="email"
            className="form-item"
            rules={[
              { required: true, message: `${t("common:vui_long_nhap")} ${t("email")}` },
              {
                type: "email",
                message: t("yeu_cau_email"),
              },
            ]}
          >
            <Input className="form-item-input" />
          </Form.Item>
          <Form.Item
            label={t("mat_khau")}
            name="password"
            className="form-item"
            rules={[{ required: true, message: `${t("common:vui_long_nhap")} ${t("mat_khau")}` }]}
          >
            <Input.Password className="form-item-input" />
          </Form.Item>
          <Button type="primary" htmlType="submit" className="submit-button">
            {t("dang_nhap")}
          </Button>
        </Form>
        <button className="forgot-password" onClick={() => setOpenForgotPassword(true)}>
          {t("quen_mat_khau")}
        </button>
      </div>
    </div>
  );
};
