import { useEffect, useState } from "react";
import { Button, Carousel, Collapse, Progress, Tooltip } from "antd";
// import { Header } from "../../components/Header";
// import { Sidebar } from "../../components/Sidebar";
import { Link } from "react-router-dom";
// import { Footer } from "../../components/Footer";
import { local } from "../../helper/localStorage";
import overLoader from "../../components/overLoader";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { PaymentMonth, QuotaInfo, UserInfo } from "../../types/common";
import { getInfoAccount, getQuotaInfo } from "../../redux/slices/userSlice";
import { useTranslation } from "react-i18next";
import images from "../../assets/images";
import dayjs from "dayjs";
import { getPaymentByMonth } from "../../redux/slices/paymentSlice";

export const Dashboard = () => {
  const [month, setMonth] = useState<string>(dayjs().format("MM"));
  const [activeKey, setActiveKey] = useState<string | string[]>(["0"]);
  const [language, setLanguage] = useState<string>("ja");

  // redux
  const dispatch = useAppDispatch();
  const authLoading = useAppSelector((state) => state.auth.loading);
  const userLoading = useAppSelector((state) => state.user.loading);
  const quotaLoading = useAppSelector((state) => state.user.quotaLoading);
  const paymentLoading = useAppSelector((state) => state.payment.loading);
  const userInfo: UserInfo = useAppSelector((state) => state.user.userInfo) as UserInfo;
  const quotaInfo: QuotaInfo = useAppSelector((state) => state.user.quotaInfo) as QuotaInfo;
  const paymentMonth: PaymentMonth | null = useAppSelector((state) => state.payment.paymentMonth);
  //   const contractInfo: ContractInfo[] = useAppSelector((state) => state.user.contractInfo) as ContractInfo[];
  const token: any = local.StorageGetSecure("access_token");
  const languageLocal = local.StorageGetSecure("language");
  const { t, i18n } = useTranslation(["dashboard", "common", "register_package"]);

  useEffect(() => {
    if (token) {
      dispatch(getInfoAccount());
      //   dispatch(getContractInfo());
    }
  }, [dispatch, token]);
  useEffect(() => {
    if (userInfo?.list_sim?.length > 0) {
      dispatch(getQuotaInfo(userInfo?.list_sim[0]));
    }
  }, [dispatch, userInfo?.list_sim]);
  useEffect(() => {
    if (userInfo?.list_sim?.length > 0) {
      dispatch(getPaymentByMonth({ year: dayjs().format("YYYY"), month: month }));
    }
  }, [dispatch, userInfo?.list_sim, month]);
  useEffect(() => {
    i18n.changeLanguage(languageLocal || "ja");
    setLanguage(languageLocal || language);
  }, [i18n, language, languageLocal]);

  const handleShowDataUsage = async (phoneNumber: string) => {
    await dispatch(getQuotaInfo(phoneNumber));
  };
  const handleCollapseChange = (key: string | string[]) => {
    if (key !== activeKey) {
      handleShowDataUsage(userInfo?.list_sim[Number(key)]);
    }
    setActiveKey(key);
  };
  const convertNameToFullWidth = (name: string) => {
    return name.replace(/[A-Za-z0-9]/g, (s) => String.fromCharCode(s.charCodeAt(0) + 0xfee0));
  };

  const bannerList = [
    {
      image: images.image_slider_banner_1,
      alt: "",
    },
    {
      image: images.image_slider_banner_2,
      alt: "",
    },
  ];
  const promotionList = [
    {
      image: images.image_promotion,
      alt: "",
      text: t("khuyen_mai_1"),
      description: t("mo_ta_khuyen_mai_1"),
      link: "/register_service_package",
    },
  ];
  const phoneNumberList: Array<any> = userInfo?.list_sim?.map((item: any, index: number) => {
    return {
      key: index,
      label: <span className="phone-number-item">{item}</span>,
      children: quotaLoading ? (
        <div className="data-usage">
          <span className="phone-number-item">{t("common:dang_tai_du_lieu")}</span>
        </div>
      ) : !Array.isArray(quotaInfo) ? (
        <div className="data-usage">
          <span className="data-package-name">{quotaInfo?.planCode}</span>
          <div className="data-usage-chart">
            <Tooltip title={`${t("da_su_dung")} ${quotaInfo?.used} MB`} color="#4533f8">
              <Progress
                type="circle"
                strokeWidth={8}
                strokeColor="#8af0c1"
                trailColor="#bebebe"
                percent={quotaInfo?.remain ? (quotaInfo?.remain / quotaInfo?.total) * 100 : 0}
                format={() => (
                  <div className="data-info">
                    <p className="data-info-text">{t("dung_luong_tong")}</p>
                    <span className="data-info-number">{quotaInfo?.remain}</span>
                    <span className="data-info-unit">MB</span>
                  </div>
                )}
              />
            </Tooltip>
          </div>
          {/* <button className="data-buy">
            <span className="data-buy-icon">
              <i className="fa-sharp fa-solid fa-coins"></i>
            </span>
            <span className="data-buy-text">{t("mua_them_du_lieu")}</span>
          </button> */}
          <Link to="/service_info" className="check-info check-contract">
            <span className="check-info-icon">
              <i className="fa-light fa-gear"></i>
            </span>
            <span className="check-info-text">{t("kiem_tra_hop_dong")}</span>
          </Link>
          <Link to="/usage_history" className="check-info check-history">
            <span className="check-info-icon">
              <i className="fa-light fa-rectangle-list"></i>
            </span>
            <span className="check-info-text">{t("kiem_tra_lich_su")}</span>
          </Link>
        </div>
      ) : (
        <div className="data-usage">
          <span className="phone-number-item">{t("common:khong_co_du_lieu")}</span>
        </div>
      ),
    };
  });

  return (
    <div className="wrapper dashboard">
      {authLoading && overLoader()}
      {userLoading && overLoader()}
      {/* {quotaLoading && overLoader()} */}
      {paymentLoading && overLoader()}
      <div className="main">
        <div className="main-content">
          <Carousel autoplay className="main-content-banner">
            {bannerList.map((item, index) => (
              <div className="main-content-banner-item" key={index}>
                <img src={item.image} alt={item.alt} />
              </div>
            ))}
          </Carousel>
          <div className="main-content-dashboard">
            <div className="dashboard-list">
              <div className="dashboard-item">
                <div className="user-info">
                  <h1 className="user-info-title">{t("common:thong_tin_khach_hang")}</h1>
                  {/* <h2 className="user-info-subtitle">{t("common:thong_tin_khach_hang")}</h2> */}
                  <span className="user-info-name">
                    {userInfo?.name_kana
                      ? convertNameToFullWidth(`${userInfo?.name} ${userInfo?.name_kana}`)
                      : convertNameToFullWidth(`${userInfo?.name}`)}
                  </span>
                  <Link to="/customer_info" className="user-info-detail">
                    <Button>{t("common:chi_tiet")}</Button>
                  </Link>
                </div>
              </div>
              <div className="dashboard-item">
                <div className="balance-info">
                  <h1 className="balance-info-title">{t("so_du_tai_khoan")}</h1>
                  <div className="balance-info-detail">
                    <div className="balance-info-detail-amount">
                      <div className="amount-money-icon">
                        <i className="fa-solid fa-coins"></i>
                      </div>
                      <div className="amount-money-text">
                        <span className="amount-money-number">{userInfo?.wallet}</span>
                        <span className="amount-money-unit">円</span>
                      </div>
                    </div>
                    <div className="balance-info-detail-action">
                      <Button type="primary" className="recharge">
                        <Link to="/payment">
                          <span className="recharge-icon">
                            <i className="fa-light fa-circle-dollar"></i>
                          </span>
                          <span className="recharge-text">{t("nap_tien")}</span>
                        </Link>
                      </Button>
                      {/* <Button className="payment">
                        <Link to="/#">{t("phuong_thuc_thanh_toan")}</Link>
                      </Button> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="dashboard-item">
                <div className="phone-number">
                  <h1 className="phone-number-title">{t("danh_sach_so_dien_thoai")}</h1>
                  <div className="phone-number-list">
                    {userInfo?.list_sim?.length > 0 ? (
                      <Collapse
                        items={phoneNumberList}
                        defaultActiveKey={["0"]}
                        onChange={(key) => key.length > 0 && handleCollapseChange(key)}
                        accordion
                        ghost
                        className="phone-number-list-accordion"
                      />
                    ) : (
                      <div className="phone-number-item">
                        <span className="phone-number-item-text">{t("common:khong_co_du_lieu")}</span>
                        <Link to="/register_service_package" className="phone-number-item-link">
                          {t("register_package:dang_ki_goi_dich_vu")}
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="dashboard-item">
                <div className="usage-fee">
                  <div className="usage-fee-item">
                    <div className="monthly-payment-fee">
                      <h1 className="monthly-payment-fee-title">{t("cuoc_phi_su_dung")}</h1>
                      <div className="monthly-payment-fee-slider">
                        <button
                          className={`monthly-payment-fee-slider-button prev${Number(month) === 1 ? " disabled" : ""}`}
                          onClick={() =>
                            Number(month) > 1 &&
                            setMonth(
                              dayjs()
                                .month(Number(month) - 2)
                                .format("MM"),
                            )
                          }
                        >
                          <i className="fa-solid fa-arrow-left"></i>
                        </button>
                        <div className="monthly-payment-fee-content">
                          {languageLocal === "vi" ? (
                            <>
                              <span className="monthly-payment-fee-content-text">{t("phi_thanh_toan_thang")} </span>
                              <span className="monthly-payment-fee-content-month">
                                {`${month}/${dayjs().format("YYYY")}`}
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="monthly-payment-fee-content-month">
                                {`${dayjs().format("YYYY")}/${month}`}
                              </span>
                              <span className="monthly-payment-fee-content-text">{t("phi_thanh_toan_thang")}</span>
                            </>
                          )}
                        </div>
                        <button
                          className={`monthly-payment-fee-slider-button next${Number(month) === 12 ? " disabled" : ""}`}
                          onClick={() => Number(month) < 12 && setMonth(dayjs().month(Number(month)).format("MM"))}
                        >
                          <i className="fa-solid fa-arrow-right"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="usage-fee-item">
                    <div className="payment-status">
                      {/* <span className="payment-status-label">{t("da_thanh_toan")}</span> */}
                      <div className="payment-status-detail">
                        <div className="payment-status-detail-amount">
                          <span className="payment-status-detail-amount-text">{t("common:tong")}: </span>
                          <span className="payment-status-detail-amount-number">{paymentMonth?.minPayment}</span>
                          <span className="payment-status-detail-amount-unit"> 円</span>
                        </div>
                        <div className="payment-status-detail-action">
                          {/* <a href="/#" className="payment-status-detail-action-item recharge">
                            <span className="recharge-icon">
                              <i className="fa-light fa-circle-dollar"></i>
                            </span>
                            <span className="recharge-text">{t("nap_tien")}</span>
                          </a> */}
                          <Link to="/payment" className="payment-status-detail-action-item check-fee">
                            {t("kiem_tra_so_tien_can_thanh_toan")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="usage-fee-item">
                    <div className="promotion">
                      <h1 className="promotion-title">{t("khuyen_mai")}</h1>
                      <div className="promotion-list">
                        {promotionList?.map((item, index) => (
                          <Link to={item.link} className="promotion-item" key={index}>
                            <div className="promotion-item-image">
                              <img src={item.image} alt={item.alt} />
                            </div>
                            <div className="promotion-item-info">
                              <h2 className="promotion-item-text">{item.text}</h2>
                              <p className="promotion-item-description">{item.description}</p>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
      {/* <div className={`overlay${openSidebar ? " active" : ""}`} onClick={() => setOpenSidebar(!openSidebar)}></div> */}
    </div>
  );
};
