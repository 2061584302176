import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { UserInfo } from "../../types/common";
import { local } from "../../helper/localStorage";
import { getInfoAccount, updateInfoAccount, verifyInfoAccount } from "../../redux/slices/userSlice";
import overLoader from "../../components/overLoader";
import { Button, Form, Input, Select, Tabs, TabsProps, Upload, UploadProps } from "antd";
import dayjs from "dayjs";
import selectList from "../../utils/common";
import images from "../../assets/images/index";
import { RcFile } from "antd/es/upload";
import { userApi } from "../../api/userApi";
import { useTranslation } from "react-i18next";
import showMessage from "../../components/openMessage";
import { useLocation } from "react-router-dom";
import { session } from "../../helper/sessionStorage";

export const CustomerInfo = () => {
  const [formUserInfo] = Form.useForm();
  const [formAddressInfo] = Form.useForm();
  const [formShippingInfo] = Form.useForm();
//   const [formPassportInfo] = Form.useForm();
  const [formVerifyImage] = Form.useForm();

  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [imageBase64, setImageBase64] = useState({
    image_front: "",
    image_end: "",
    image_face: "",
  });
  const [activeTabKey, setActiveTabKey] = useState<string>("1");

  const { t } = useTranslation(["customer_info", "common"]);
  const state = useLocation();
  const isVerified = state?.state?.isVerified;

  // redux
  const dispatch = useAppDispatch();
  const authLoading = useAppSelector((state) => state.auth.loading);
  const userLoading = useAppSelector((state) => state.user.loading);
  const userInfo: UserInfo = useAppSelector((state) => state.user.userInfo) as UserInfo;

  // local + session
  const languageLocal = local.StorageGetSecure("language");
  const token: any = local.StorageGetSecure("access_token");
  const storedData = session.StorageGetSecure("user_info");

  useEffect(() => {
    if (token) {
      dispatch(getInfoAccount());
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (userInfo) {
      formUserInfo.setFieldsValue({
        name: userInfo?.name,
        name_kana: userInfo?.name_kana ? userInfo?.name_kana : storedData?.name_kana ? storedData?.name_kana : null,
        passport: userInfo?.passport
          ? selectList.countryList.find((item) => item.value === userInfo?.passport)?.value
          : storedData?.passport
          ? selectList.countryList.find((item) => item.value === storedData?.passport)?.value
          : null,
        gender: userInfo?.gender ? userInfo?.gender : storedData?.gender ? storedData?.gender : null,
        phone: userInfo?.phone ? userInfo?.phone : storedData?.phone ? storedData?.phone : null,
        email: userInfo?.email ? userInfo?.email : storedData?.email ? storedData?.email : null,
      });
      formAddressInfo.setFieldsValue({
        post_code: userInfo?.post_code ? userInfo?.post_code : storedData?.post_code ? storedData?.post_code : null,
        prefecture: userInfo?.prefecture
          ? userInfo?.prefecture
          : storedData?.prefecture
          ? storedData?.prefecture
          : null,
        city: userInfo?.city ? userInfo?.city : storedData?.city ? storedData?.city : null,
        address: userInfo?.address ? userInfo?.address : storedData?.address ? storedData?.address : null,
        area: userInfo?.area ? userInfo?.area : storedData?.area ? storedData?.area : null,
        building: userInfo?.building ? userInfo?.building : storedData?.building ? storedData?.building : null,
      });
      formShippingInfo.setFieldsValue({
        payment_method:
          userInfo?.shipping?.length > 0 && userInfo?.shipping[0]?.payment_method
            ? userInfo?.shipping[0]?.payment_method
            : storedData?.payment_method
            ? storedData?.payment_method
            : null,
        payment_code:
          userInfo?.shipping?.length > 0 && userInfo?.shipping[0]?.payment_code
            ? userInfo?.shipping[0]?.payment_code
            : storedData?.payment_code
            ? storedData?.payment_code
            : null,
        shipping_method: (
          userInfo?.shipping?.length > 0 && userInfo?.shipping[0]?.shipping_method !== 0
            ? userInfo?.shipping[0]?.shipping_method
            : null
        )
          ? userInfo?.shipping[0]?.shipping_method
          : storedData?.shipping_method
          ? storedData?.shipping_method
          : null,
        shipping_code:
          userInfo?.shipping?.length > 0 && userInfo?.shipping[0]?.shipping_code
            ? userInfo?.shipping[0]?.shipping_code
            : storedData?.shipping_code
            ? storedData?.shipping_code
            : null,
        shipping_post_code:
          userInfo?.shipping?.length > 0 && userInfo?.shipping[0]?.post_code
            ? userInfo?.shipping[0]?.post_code
            : storedData?.shipping_post_code
            ? storedData?.shipping_post_code
            : null,
        shipping_address01:
          userInfo?.shipping?.length > 0 && userInfo?.shipping[0]?.address01
            ? userInfo?.shipping[0]?.address01
            : storedData?.shipping_address01
            ? storedData?.shipping_address01
            : null,
      });
    //   formPassportInfo.setFieldsValue({
    //     iccd: userInfo?.iccd ? userInfo?.iccd : storedData?.iccd ? storedData?.iccd : null,
    //     coe: userInfo?.coe
    //       ? selectList.coeList.find((item) => item.value === userInfo?.coe)?.value
    //       : storedData?.coe
    //       ? selectList.coeList.find((item) => item.value === storedData?.coe)?.value
    //       : null,
    //     expire_time_coe: userInfo?.expire_time_coe
    //       ? dayjs(userInfo.expire_time_coe)
    //       : storedData?.expire_time_coe
    //       ? dayjs(storedData?.expire_time_coe)
    //       : null,
    //   });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, formUserInfo, formAddressInfo, formShippingInfo, languageLocal]);

  const handleUpdateForm = async () => {
    let dataSend: any = {};
    dataSend = {
      phone: formUserInfo.getFieldValue("phone"),
      email: formUserInfo.getFieldValue("email"),
      post_code: formAddressInfo.getFieldValue("post_code"),
      prefecture: formAddressInfo.getFieldValue("prefecture"),
      city: formAddressInfo.getFieldValue("city"),
      address: formAddressInfo.getFieldValue("address"),
      area: formAddressInfo.getFieldValue("area"),
      building: formAddressInfo.getFieldValue("building"),

      payment_method: formShippingInfo.getFieldValue("payment_method"),
      shipping_method: formShippingInfo.getFieldValue("shipping_method"),
      shipping_code: formShippingInfo.getFieldValue("shipping_code"),
      shipping_post_code: formShippingInfo.getFieldValue("shipping_post_code"),
      shipping_address01: formShippingInfo.getFieldValue("shipping_address01"),

    //   iccd: formPassportInfo.getFieldValue("iccd"),
    //   coe: formPassportInfo.getFieldValue("coe"),
    //   expire_time_coe: dayjs(formPassportInfo.getFieldValue("expire_time_coe")).format("YYYY-MM-DD"),
    };

    if (userInfo?.verify?.length === 0) {
      dataSend = {
        ...dataSend,
        image_front_base64: imageBase64.image_front,
        image_end_base64: imageBase64.image_end,
        image_face_base64: imageBase64.image_face,
      };
    }

    setOpenEdit(false);
    try {
      const resData: any = await dispatch(
        updateInfoAccount({
          ...userInfo,
          ...dataSend,
        }),
      );
      if (resData?.payload?.code === 200) {
        showMessage.success(resData?.payload?.msg);
        dispatch(getInfoAccount());
      } else {
        showMessage.error(resData?.payload?.msg);
      }
    } catch (error: any) {
      showMessage.error(error?.payload?.msg);
    }
  };
  const handleUpperCase = (e: any) => {
    e.target.value = e.target.value.toUpperCase();
  };

  let statusVerify = 0;
  if (userInfo?.verify?.length === 0) {
    statusVerify = 0;
  } else if (userInfo?.verify?.length > 0) {
    switch (userInfo?.verify[0].status) {
      case 0:
        statusVerify = 3;
        break;
      case 1:
        statusVerify = 1;
        break;
      case 2:
        statusVerify = 2;
        break;
      default:
        statusVerify = 0;
        break;
    }
  }

  const getBase64 = (img: RcFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  const propsUpload = (field: string): UploadProps => ({
    listType: "picture",
    maxCount: 1,
    beforeUpload: async (file: RcFile) => {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png";
      if (!isJpgOrPng) {
        showMessage.error("Vui lòng chọn file ảnh có định dạng jpg hoặc png");
        return Upload.LIST_IGNORE;
      }
      const base64 = await getBase64(file);
      setImageBase64({ ...imageBase64, [field]: base64 });
      return false;
    },
  });

  const handlePostCodeChange = async (e: any) => {
    const postCode = e.target.value;
    if (postCode.length === 7) {
      try {
        const resData: any = await userApi.getAddressByPostCode(postCode);
        const address = resData.data.data[0].ja;
        if (e.target.id === "post_code") {
          formAddressInfo.setFieldsValue({
            prefecture: address.prefecture,
            city: address.address1,
            address: address.address2,
          });
        } else if (e.target.id === "shipping_post_code") {
          formAddressInfo.setFieldsValue({
            shipping_address01: address.prefecture + address.address1 + address.address2,
          });
        }
      } catch (error: any) {
        if (error.message === "Request failed with status code 404") {
          formAddressInfo.setFields([{ name: "post_code", errors: [t("vui_long_nhap_dung_postcode")] }]);
        }
      }
    }
  };
  const handleStoreFormData = (data: any) => {
    session.StorageSetSecure("user_info", storedData ? { ...storedData, ...data } : data);
    activeTabKey !== "5" && setActiveTabKey(`${+activeTabKey + 1}`);
  };
  const handleSubmitForm = async () => {
    const dataSend = {
      ...storedData,
      expire_time_coe: dayjs(storedData?.expire_time_coe).format("YYYY-MM-DD"),
      image_front_base64: imageBase64.image_front,
      image_end_base64: imageBase64.image_end,
      image_face_base64: imageBase64.image_face,
    };

    try {
      const resData: any = await dispatch(verifyInfoAccount(dataSend));
      dispatch(getInfoAccount());
      if (resData.payload.code === 200) {
        showMessage.success(resData?.payload?.msg);
        formUserInfo.resetFields();
        formAddressInfo.resetFields();
        formShippingInfo.resetFields();
        // formPassportInfo.resetFields();
        formVerifyImage.resetFields();
        session.StorageRemoveSecure("user_info");
        setActiveTabKey("1");
      } else {
        showMessage.error(resData?.payload?.msg);
      }
    } catch (error: any) {
      showMessage.error(error?.payload?.msg);
    }
  };
  const ButtonUpdateInfo = () => {
    return (
      <div className="customer-info-button">
        {isVerified !== null && userInfo?.verify?.length > 0 ? (
          <>
            <Button
              disabled={false}
              onClick={() => setOpenEdit(!openEdit)}
              className="customer-form-edit"
              type={openEdit ? "default" : "primary"}
              htmlType="button"
            >
              {openEdit ? t("common:huy") : t("common:chinh_sua")}
            </Button>
            {openEdit && (
              <Button type="primary" htmlType="submit" className="customer-form-submit">
                {t("common:luu")}
              </Button>
            )}
          </>
        ) : (
          <>
            <Button
              type="default"
              onClick={() => setActiveTabKey(`${+activeTabKey - 1}`)}
              disabled={activeTabKey === "1"}
              className="customer-form-prev"
            >
              {t("common:quay_lai")}
            </Button>
            {activeTabKey !== "5" ? (
              <Button type="primary" htmlType="submit" className="customer-form-next">
                {t("common:tiep_theo")}
              </Button>
            ) : (
              <Button type="primary" htmlType="submit" className="customer-form-next">
                {t("common:luu")}
              </Button>
            )}
          </>
        )}
      </div>
    );
  };

  const isFormDisabled = userInfo?.verify?.length === 0 ? false : true;

  const tabsItem: TabsProps["items"] = [
    {
      key: "1",
      //   label: "個人情報",
      label: t("thong_tin_ca_nhan"),
      children: (
        <Form
          layout="vertical"
          disabled={!openEdit && isFormDisabled}
          className="customer-item-info customer-form"
          //   labelCol={{ span: 24 }}
          //   wrapperCol={{ span: 24 }}
          form={formUserInfo}
          initialValues={{
            name: userInfo?.name || null,
            name_kana: storedData?.name_kana ? storedData?.name_kana : userInfo?.name_kana || null,
            passport: userInfo?.passport ? userInfo?.passport : storedData?.passport ? storedData?.passport : null,
            // birth_day: storedData?.birth_day
            //   ? dayjs(storedData?.birth_day, "YYYY-MM-DD")
            //   : userInfo?.birth_day
            //   ? dayjs(userInfo?.birth_day)
            //   : null,
            gender: storedData?.gender ? storedData?.gender : userInfo?.gender || null,
            phone: storedData?.phone ? storedData?.phone : userInfo?.phone || null,
            email: storedData?.email ? storedData?.email : userInfo?.email || null,
          }}
          scrollToFirstError
          onFinish={userInfo?.verify?.length > 0 ? handleUpdateForm : handleStoreFormData}
        >
          <Form.Item
            // label="フルネーム"
            label={t("ho_ten")}
            name="name"
            rules={[
              { required: true, message: `${t("common:vui_long_nhap")} ${t("ho_ten")}` },
              {
                pattern: /^[A-Z ]+$/,
                message: t("yeu_cau_ho_ten"),
              },
            ]}
          >
            <Input type="text" placeholder={t("ho_ten")} onInput={handleUpperCase} disabled />
          </Form.Item>
          <Form.Item
            // label="フルネーム (カナ)"
            label={t("ho_ten_kana")}
            name="name_kana"
            rules={[
              { required: true, message: `${t("common:vui_long_nhap")} ${t("ho_ten_kana")}` },
              {
                pattern: /^([ァ-ン]|ー)+$/,
                message: t("yeu_cau_ho_ten_kana"),
              },
            ]}
          >
            <Input
              type="text"
              placeholder={t("ho_ten_kana")}
              disabled={userInfo?.name_kana !== null || isFormDisabled}
            />
          </Form.Item>
          <Form.Item
            label={t("quoc_tich")}
            name="passport"
            rules={[{ required: true, message: `${t("common:vui_long_chon")} ${t("quoc_tich")}` }]}
          >
            <Select
              options={selectList.countryList}
              placeholder={t("quoc_tich")}
              disabled={userInfo?.passport !== null || isFormDisabled}
            />
          </Form.Item>
          {/* <Form.Item
            //   label="生年月日"
            label={t("ngay_sinh")}
            name="birth_day"
            rules={[{ required: true, message: `${t("common:vui_long_chon")} ${t("ngay_sinh")}` }]}
          >
            <DatePicker
              className="customer-form-input"
              format="YYYY-MM-DD"
              placeholder={t("ngay_sinh")}
              disabled={userInfo?.birth_day !== null || isFormDisabled}
            />
          </Form.Item> */}
          <Form.Item
            //   label="性別"
            label={t("gioi_tinh")}
            name="gender"
            rules={[{ required: true, message: `${t("common:vui_long_chon")} ${t("gioi_tinh")}` }]}
          >
            <Select
              options={selectList.genderList}
              className="customer-form-input"
              placeholder={t("gioi_tinh")}
              disabled={userInfo?.gender !== null || isFormDisabled}
            />
          </Form.Item>
          <Form.Item
            // label="電話番号"
            label={t("so_dien_thoai")}
            name="phone"
            rules={[
              { required: true, message: `${t("common:vui_long_nhap")} ${t("so_dien_thoai")}` },
              { pattern: /^[0-9]*$/, message: t("yeu_cau_dinh_dang_so") },
              { min: 10, max: 11, message: t("yeu_cau_so_dien_thoai") },
            ]}
          >
            <Input type="text" className="customer-form-input" placeholder={t("so_dien_thoai")} />
          </Form.Item>
          <Form.Item
            // label="メールアドレス"
            label={t("email")}
            name="email"
            rules={[
              { required: true, message: `${t("common:vui_long_nhap")} ${t("email")}` },
              {
                type: "email",
                message: t("yeu_cau_email"),
              },
            ]}
          >
            <Input type="email" className="customer-form-input" placeholder={t("email")} />
          </Form.Item>
          {ButtonUpdateInfo()}
        </Form>
      ),
      disabled: userInfo?.verify?.length === 0 || isVerified,
    },
    {
      key: "2",
      //   label: "住所情報",
      label: t("thong_tin_dia_chi"),
      children: (
        <Form
          layout="vertical"
          disabled={!openEdit && isFormDisabled}
          className="customer-item-info customer-form"
          //   labelCol={{ span: 6 }}
          //   wrapperCol={{ span: 12 }}
          form={formAddressInfo}
          initialValues={{
            post_code: storedData?.post_code ? storedData?.post_code : userInfo?.post_code || null,
            prefecture: storedData?.prefecture ? storedData?.prefecture : userInfo?.prefecture || null,
            city: storedData?.city ? storedData?.city : userInfo?.city || null,
            address: storedData?.address ? storedData?.address : userInfo?.address || null,
            area: storedData?.area ? storedData?.area : userInfo?.area || null,
            building: storedData?.building ? storedData?.building : userInfo?.building || null,
          }}
          scrollToFirstError
          onFinish={userInfo?.verify?.length > 0 ? handleUpdateForm : handleStoreFormData}
        >
          <Form.Item
            // label="郵便番号"
            label={t("ma_buu_dien")}
            name="post_code"
            rules={[
              { required: true, message: `${t("common:vui_long_nhap")} ${t("ma_buu_dien")}` },
              {
                pattern: /^[0-9]+$/,
                min: 7,
                max: 7,
                message: t("yeu_cau_ma_buu_dien"),
              },
            ]}
          >
            <Input
              type="text"
              inputMode="numeric"
              className="customer-form-input"
              onChange={handlePostCodeChange}
              //   placeholder="Nhập mã bưu điện"
              placeholder={t("ma_buu_dien")}
            />
          </Form.Item>
          <Form.Item
            //   label="都道府県"
            label={t("tinh")}
            name="prefecture"
            rules={[{ required: true, message: `${t("common:vui_long_nhap")} ${t("tinh")}` }]}
          >
            <Input type="text" className="customer-form-input" placeholder={t("tinh")} />
          </Form.Item>
          {openEdit && (
            <>
              <Form.Item
                //   label="市区町村"
                label={t("thanh_pho")}
                name="city"
                rules={[{ required: true, message: `${t("common:vui_long_nhap")} ${t("thanh_pho")}` }]}
              >
                <Input type="text" className="customer-form-input" placeholder={t("thanh_pho")} />
              </Form.Item>
              <Form.Item
                //   label="住所"
                label={t("dia_chi")}
                name="address"
                rules={[{ required: true, message: `${t("common:vui_long_nhap")} ${t("dia_chi")}` }]}
              >
                <Input type="text" className="customer-form-input" placeholder={t("dia_chi")} />
              </Form.Item>
              <Form.Item
                //   label="番地"
                label={t("khu_vuc")}
                name="area"
                rules={[{ required: true, message: `${t("common:vui_long_nhap")} ${t("khu_vuc")}` }]}
              >
                <Input type="text" className="customer-form-input" placeholder={t("khu_vuc")} />
              </Form.Item>
              <Form.Item
                //   label="物件名"
                label={t("toa_nha")}
                name="building"
              >
                <Input type="text" className="customer-form-input" placeholder={t("toa_nha")} />
              </Form.Item>
            </>
          )}
          {ButtonUpdateInfo()}
        </Form>
      ),
      disabled: userInfo?.verify?.length === 0 || isVerified,
    },
    {
      key: "3",
      //   label: "お届け先情報",
      label: t("thong_tin_giao_hang"),
      children: (
        <Form
          layout="vertical"
          disabled={!openEdit && isFormDisabled}
          className="customer-item-info customer-form"
          //   labelCol={{ span: 6 }}
          //   wrapperCol={{ span: 12 }}
          form={formShippingInfo}
          initialValues={{
            payment_method:
              userInfo?.shipping?.length > 0 && userInfo?.shipping[0]?.payment_method
                ? userInfo?.shipping[0]?.payment_method
                : storedData?.payment_method
                ? storedData?.payment_method
                : null,
            payment_code:
              userInfo?.shipping?.length > 0 && userInfo?.shipping[0]?.payment_code
                ? userInfo?.shipping[0]?.payment_code
                : storedData?.payment_code
                ? storedData?.payment_code
                : null,
            shipping_method:
              userInfo?.shipping?.length > 0 && userInfo?.shipping[0]?.shipping_method
                ? userInfo?.shipping[0]?.shipping_method
                : storedData?.shipping_method
                ? storedData?.shipping_method
                : null,
            shipping_code:
              userInfo?.shipping?.length > 0 && userInfo?.shipping[0]?.shipping_code
                ? userInfo?.shipping[0]?.shipping_code
                : storedData?.shipping_code
                ? storedData?.shipping_code
                : null,
            shipping_post_code:
              userInfo?.shipping?.length > 0 && userInfo?.shipping[0]?.post_code
                ? userInfo?.shipping[0]?.post_code
                : storedData?.shipping_post_code
                ? storedData?.shipping_post_code
                : null,
            shipping_address01:
              userInfo?.shipping?.length > 0 && userInfo?.shipping[0]?.address01
                ? userInfo?.shipping[0]?.address01
                : storedData?.shipping_address01
                ? storedData?.shipping_address01
                : null,
          }}
          scrollToFirstError
          onFinish={userInfo?.verify?.length > 0 ? handleUpdateForm : handleStoreFormData}
        >
          <Form.Item
            label={t("phuong_thuc_thanh_toan")}
            name="payment_method"
            rules={[
              {
                required: true,
                // type: "string",
                // whitespace: true,
                // message: "Vui lòng chọn phương thức thanh toán",
                message: `${t("common:vui_long_chon")} ${t("phuong_thuc_thanh_toan")}`,
              },
            ]}
          >
            <Select options={selectList.paymentMethodList} placeholder={t("phuong_thuc_thanh_toan")} />
          </Form.Item>
          <Form.Item
            // label="支払いコード (Smartpit)"
            label={`${t("ma_thanh_toan")} (Smartpit)`}
            name="payment_code"
          >
            <Input
              type="text"
              pattern="[0-9]*"
              inputMode="numeric"
              className="customer-form-input"
              placeholder={`${t("ma_thanh_toan")} (Smartpit)`}
              disabled
            />
          </Form.Item>
          <Form.Item
            // label="配送方法"
            label={t("phuong_thuc_giao_hang")}
            name="shipping_method"
            rules={[
              {
                required: true,
                // type: "string",
                pattern: /^[1-9]*$/,
                whitespace: true,
                message: `${t("common:vui_long_chon")} ${t("phuong_thuc_giao_hang")}`,
              },
            ]}
          >
            <Select options={selectList.shippingMethodList} placeholder={t("phuong_thuc_giao_hang")} />
          </Form.Item>
          <Form.Item
            // label="配送コード"
            label={t("ma_giao_hang")}
            name="shipping_code"
            rules={[
              { required: true, message: `${t("common:vui_long_nhap")} ${t("ma_giao_hang")}` },
              {
                pattern: /^[0-9]*$/,
                message: t("yeu_cau_dinh_dang_so"),
              },
            ]}
          >
            <Input type="text" inputMode="numeric" className="customer-form-input" placeholder={t("ma_giao_hang")} />
          </Form.Item>
          <Form.Item label={t("ma_buu_dien")} name="shipping_post_code">
            <Input
              type="text"
              className="customer-form-input"
              placeholder={t("ma_buu_dien")}
              onChange={handlePostCodeChange}
            />
          </Form.Item>
          <Form.Item label={t("dia_chi")} name="shipping_address01">
            <Input type="text" className="customer-form-input" placeholder={t("dia_chi")} />
          </Form.Item>
          {ButtonUpdateInfo()}
        </Form>
      ),
      disabled: userInfo?.verify?.length === 0 || isVerified,
    },
    // {
    //   key: "4",
    //   //   label: "本人確認",
    //   label: t("thong_tin_cu_tru"),
    //   children: (
    //     <Form
    //       layout="vertical"
    //       disabled={!openEdit && isFormDisabled}
    //       className="customer-item-info customer-form"
    //       //   labelCol={{ span: 6 }}
    //       //   wrapperCol={{ span: 12 }}
    //       form={formPassportInfo}
    //       initialValues={{
    //         iccd: userInfo?.iccd ? userInfo?.iccd : storedData?.iccd ? storedData?.iccd : null,
    //         coe: userInfo?.coe ? userInfo?.coe : storedData?.coe ? storedData?.coe : null,
    //         expire_time_coe: storedData?.expire_time_coe
    //           ? dayjs(storedData?.expire_time_coe)
    //           : userInfo?.expire_time_coe
    //           ? dayjs(userInfo.expire_time_coe)
    //           : null,
    //       }}
    //       scrollToFirstError
    //       onFinish={userInfo?.verify?.length > 0 ? handleUpdateForm : handleStoreFormData}
    //     >
    //       <Form.Item
    //         label={t("so_the_cu_tru")}
    //         name="iccd"
    //         rules={[
    //           { required: true, message: `${t("common:vui_long_nhap")} ${t("so_the_cu_tru")}` },
    //           { pattern: /^[0-9]*$/, message: t("yeu_cau_dinh_dang_so") },
    //         ]}
    //       >
    //         <Input type="text" inputMode="numeric" className="customer-form-input" placeholder={t("so_the_cu_tru")} />
    //       </Form.Item>
    //       <Form.Item
    //         label={t("hinh_thuc_cu_tru")}
    //         name="coe"
    //         rules={[{ required: true, message: `${t("common:vui_long_chon")} ${t("hinh_thuc_cu_tru")}` }]}
    //       >
    //         <Select options={selectList.coeList} placeholder={t("hinh_thuc_cu_tru")} />
    //       </Form.Item>
    //       <Form.Item
    //         // label="在留期限"
    //         label={t("thoi_han_cu_tru")}
    //         name="expire_time_coe"
    //         rules={[{ required: true, message: `${t("common:vui_long_chon")} ${t("thoi_han_cu_tru")}` }]}
    //       >
    //         <DatePicker className="customer-form-input" format="YYYY-MM-DD" placeholder={t("thoi_han_cu_tru")} />
    //       </Form.Item>
    //       {ButtonUpdateInfo()}
    //     </Form>
    //   ),
    //   disabled: userInfo?.verify?.length === 0 || isVerified,
    // },
    ...(userInfo?.verify?.length === 0
      ? [
          {
            key: "4",
            // label: "本人確認画像",
            label: t("thong_tin_xac_thuc"),
            children: (
              <Form
                layout="vertical"
                disabled={!openEdit && isFormDisabled}
                className="customer-item-info customer-form"
                // labelCol={{ span: 6 }}
                // wrapperCol={{ span: 12 }}
                form={formVerifyImage}
                scrollToFirstError
                onFinish={userInfo?.verify?.length > 0 ? handleUpdateForm : handleSubmitForm}
              >
                <Form.Item
                  label={<span className="customer-form-label">{t("anh_mat_truoc")}</span>}
                  name="image_front"
                  wrapperCol={{ span: 19 }}
                  rules={[{ required: true, message: `${t("common:vui_long_chon")} ${t("anh_mat_truoc")}` }]}
                >
                  <div className="customer-form-upload">
                    <Upload {...propsUpload("image_front")} name="image_front">
                      <Button className="customer-form-button">
                        <span className="customer-form-button-icon">
                          <i className="fa-light fa-upload"></i>
                        </span>
                        <span className="customer-form-button-text">{t("common:tai_len")}</span>
                      </Button>
                    </Upload>
                    <div className="customer-form-upload-sample">
                      <img src={images.image_front_sample} alt="" />
                    </div>
                  </div>
                </Form.Item>
                <Form.Item
                  label={<span className="customer-form-label">{t("anh_mat_sau")}</span>}
                  name="image_end"
                  wrapperCol={{ span: 19 }}
                  rules={[{ required: true, message: `${t("common:vui_long_chon")} ${t("anh_mat_sau")}` }]}
                >
                  <div className="customer-form-upload">
                    <Upload {...propsUpload("image_end")} name="image_end">
                      <Button className="customer-form-button">
                        <span className="customer-form-button-icon">
                          <i className="fa-light fa-upload"></i>
                        </span>
                        <span className="customer-form-button-text">{t("common:tai_len")}</span>
                      </Button>
                    </Upload>
                    <div className="customer-form-upload-sample">
                      <img src={images.image_end_sample} alt="" />
                    </div>
                  </div>
                </Form.Item>
                <Form.Item
                  label={<span className="customer-form-label">{t("anh_chup_cam_tay")}</span>}
                  name="image_face"
                  wrapperCol={{ span: 19 }}
                  rules={[{ required: true, message: `${t("common:vui_long_chon")} ${t("anh_chup_cam_tay")}` }]}
                >
                  <div className="customer-form-upload">
                    <Upload {...propsUpload("image_face")} name="image_face">
                      <Button className="customer-form-button">
                        <span className="customer-form-button-icon">
                          <i className="fa-light fa-upload"></i>
                        </span>
                        <span className="customer-form-button-text">{t("common:tai_len")}</span>
                      </Button>
                    </Upload>
                    <div className="customer-form-upload-sample">
                      <img src={images.image_face_sample} alt="" />
                    </div>
                  </div>
                </Form.Item>
                {ButtonUpdateInfo()}
              </Form>
            ),
            disabled: userInfo?.verify?.length === 0 || isVerified,
          },
        ]
      : []),
  ];

  return (
    <div className="wrapper customer">
      {authLoading && overLoader()}
      {userLoading && overLoader()}
      <div className="main">
        <div className="main-content">
          {statusVerify === 0 ? (
            <div className="customer-notification">
              <span className="customer-notification-icon">
                <i className="fa-light fa-circle-exclamation"></i>
              </span>
              <span className="customer-notification-text">{t("tai_khoan_chua_xac_thuc")}</span>
            </div>
          ) : statusVerify === 2 ? (
            <div className="customer-notification error">
              <span className="customer-notification-icon">
                <i className="fa-light fa-xmark"></i>
              </span>
              <span className="customer-notification-text">{t("tai_khoan_khong_duoc_xac_thuc")}</span>
            </div>
          ) : statusVerify === 3 ? (
            <div className="customer-notification pending">
              <span className="customer-notification-icon">
                <i className="fa-light fa-hourglass-end"></i>
              </span>
              <span className="customer-notification-text">{t("tai_khoan_cho_xac_thuc")}</span>
            </div>
          ) : (
            ""
          )}
          <div className="info-list">
            {/* <div className="info-item">
              <div className="customer-info">
                <h2 className="customer-info-main-title">{t("common:thong_tin_khach_hang")}</h2>
                <Row className="customer-info-main-content">
                  <Col xs={24} sm={12} lg={8} className="customer-info-main-content-item">
                    <span className="customer-info-main-content-item-title">{t("ho_ten")}: </span>
                    <span className="customer-info-main-content-item-text">{userInfo?.name}</span>
                  </Col>
                  <Col xs={24} sm={12} lg={8} className="customer-info-main-content-item">
                    <span className="customer-info-main-content-item-title">{t("ho_ten_kana")}: </span>
                    <span className="customer-info-main-content-item-text">{userInfo?.name_kana || "--"}</span>
                  </Col>
                  <Col xs={24} sm={12} lg={8} className="customer-info-main-content-item">
                    <span className="customer-info-main-content-item-title">{t("ngay_sinh")}: </span>
                    <span className="customer-info-main-content-item-text">{userInfo?.birth_day || "--"}</span>
                  </Col>
                  <Col xs={24} sm={12} lg={8} className="customer-info-main-content-item">
                    <span className="customer-info-main-content-item-title">{t("gioi_tinh")}: </span>
                    <span className="customer-info-main-content-item-text">
                      {userInfo?.gender === 1 ? t("nam") : userInfo?.gender === 2 ? t("nu") : "--"}
                    </span>
                  </Col>
                  <Col xs={24} sm={12} lg={8} className="customer-info-main-content-item">
                    <span className="customer-info-main-content-item-title">{t("so_dien_thoai")}: </span>
                    <span className="customer-info-main-content-item-text">{userInfo?.phone || "--"}</span>
                  </Col>
                  <Col xs={24} sm={12} lg={8} className="customer-info-main-content-item">
                    <span className="customer-info-main-content-item-title">{t("email")}: </span>
                    <span className="customer-info-main-content-item-text">{userInfo?.email}</span>
                  </Col>
                </Row>
              </div>
            </div> */}
            <div className="info-item">
              <Tabs
                defaultActiveKey="1"
                items={tabsItem}
                className="customer-info-tabs"
                onChange={(key) => {
                  setOpenEdit(false);
                  setActiveTabKey(key);
                }}
                activeKey={activeTabKey}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
